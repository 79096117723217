.custom-file-input {
  position: absolute;
  left: -9999px;
}

.error-message {
  color: red; /* Set the text color to red */
  font-size: 12px; /* Set the font size to a smaller size, adjust as needed */
  /* Add any additional styles you want */
}

label[for="background-input"] {
  display: inline-block;
  padding: 6px 12px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.custom-label {
  display: inline-block;
  padding: 10px;
  background-color: #97b6df;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.custom-label:hover {
  background-color: #70b5d2;
}

.custom-file-input-logo {
  position: absolute;
  left: -9999px;
}

label[for="logo-input"] {
  display: inline-block;
  padding: 6px 12px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.custom-label-logo {
  display: inline-block;
  padding: 10px;
  background-color: #97b6df;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.custom-label-logo:hover {
  background-color: #70b5d2;
}
