.video-overlay {
  position: fixed; /* Or 'absolute' if more appropriate */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100; /* Ensure it's above other content */
}
.video-overlay video {
  width: 100%; /* Adjust as needed */
  height: 100%; /* Adjust as needed */
  object-fit: cover; /* Optional: for covering the whole area */
}
