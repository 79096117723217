
.card {
    display: flex;
    flex-direction: column;
    background-color:#cac7c7f6;
    width: 11rem;
    height: 15rem;
    padding: 0rem 0.7rem 0.7rem 0.7rem;
    border-radius: 14px;
  }

  .card img {
    margin-top: 3%;
    width: 100%;
    border-radius: 10px;
    padding-bottom: 2rem;
    /* height:20rem; */
  }

  
  .card h2 {
    margin: 0;
    margin-top: 1rem;
  }
  
  .card p {
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
  
  .card .btnn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
